import { isValidLanguage, type Localization } from '@item-ind/localization';
import { isValidCountry } from '@item-ind/localization/dist/locale';

// We need a parseLocale() functionality that doesn't restrict to
// specific language/country pairs as parseLocale() from '@item-ind/localization does.
// I.e. parseLocale('zh-DE') will return localization with 'de-DE' instead of 'zh-DE' !
// We re-implement the functionality here matching valid languages for countries.
// We only check that language is valid and country is valid,
// and any combination of valid a language/country is ok.
export function parsePlainLocale(locale: string | undefined): Localization | undefined {
	const matched = (locale || '').match(/([a-z]{2})-([a-z]{2})/i);
	if (!matched) {
		return undefined;
	}
	const lang = matched[1].toLowerCase();
	if (!isValidLanguage(lang)) {
		return undefined;
	}
	const country = matched[2].toUpperCase();
	if (!isValidCountry(country)) {
		return undefined;
	}
	return {
		lang,
		country,
		locale: `${lang}-${country}`
	};
}
