import type { Localization } from '@item-ind/localization';

export const config = {
	fallbackLocalization: {
		country: 'DE',
		lang: 'de',
		locale: 'de-DE'
	} satisfies Localization,
	keycloakClientId: 'manufacturing'
};
